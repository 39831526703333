<template>
  <div class="ud-body ud-body-card ud-max-height">
    <China ref="china" @updateKey="updateKey" :warehouseList="warehouseList">
    </China>
    <!-- 左边内容 -->
    <Left ref="mapLeft" v-model:warehouseList="warehouseList"> </Left>
    <!-- 右弹窗内容 -->
    <Right
      ref="mapRight"
      :warehouseList="warehouseList"
      @showMarker="showMarker"
      @resize="resize"
    >
    </Right>
  </div>
</template>

<script>
import China from './echarts/china/index'
import Left from './index-left'
import Right from './index-right'
import * as assetsApi from '@/api/ams/assets'
export default {
  components: {
    China,
    Left,
    Right
  },
  data() {
    return {
      windowResize: 0,
      warehouseList: [],
      markerData: [],
      isShowMarker: true
    }
  },
  mounted() {
    this.init()
  },
  watch: {},
  created() {},
  methods: {
    init() {
      //
      assetsApi
        .unitAssetsReport()
        .then((res) => {
          if (res.code === 0) {
            this.warehouseList = res.data
            this.warehouseList.forEach((item, index) => {
              if (item.longitude2end) {
                item.group = 'xiamen'
              } else if (item.code === '255014009') {
                item.group = 'zhangzhuo'
              }
              item.index = index
              item.value = item.count
              item.show = true
            })
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
      window.addEventListener('resize', this.resize)
    },
    // 控制是否显示地图上点位显示
    showMarker(show) {
      this.$refs.china.restMapData(show)
    },
    updateKey(data) {
      this.$refs.mapRight.initData(data.id)
    },
    resize() {
      this.$refs.china.resize()
    }
  }
}
</script>

<style lang="less" scoped>
.ud-max-height {
  height: calc(100vh - 48px - 40px);
  min-height: 832px;
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: 0;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  .header-title {
    font-weight: bold;
    font-size: 20px;
  }
  .header-total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .total {
      margin-right: 10px;
    }
  }
}
@media screen and (max-width: 1366px) {
  :deep(.ant-card) {
    width: 332px;
  }
}
@media screen and (min-width: 1367px) {
  :deep(.ant-card) {
    width: 350px;
  }
}
</style>
